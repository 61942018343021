import React, { useState } from "react";
import axios from "axios";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import style from "./index.module.less";

function Feedback({ data: { profilePhoto, bclLogoIcon } }) {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/5b263c7c-8a5a-4e2a-b06d-ff28e95d31ca",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Layout logo={bclLogoIcon}>
      <SEO
        title="Feedback"
        description="A feedback form for our players"
        path="feedback"
      />
      <div className={style.container}>
        <div className={style.photo}>
          <Img fluid={profilePhoto.childImageSharp.fluid} />
        </div>
        <div className={style.content}>
          <h1>Feedback</h1>
          <form onSubmit={handleOnSubmit}>
            <div className={style.formGroup}>
              <label htmlFor="inputEmail">Email address</label>
              <input
                type="email"
                name="email"
                id="inputEmail"
                aria-describedby="emailHelp"
                required="required"
                placeholder="Enter email"
              />
            </div>
            <div className={style.formGroup}>
              <label htmlFor="inputName">Name</label>
              <input
                type="text"
                name="name"
                id="inputName"
                placeholder="Enter your name"
                required="required"
              />
            </div>
            <div className={style.formGroup}>
              <label htmlFor="inputMessage">Message</label>
              <textarea
                name="message"
                placeholder="How can we help you?"
                id="inputMessage"
                required="required"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={serverState.submitting}
            >
              Submit Feedback
            </button>
            {serverState.status && (
              <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
              </p>
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    bclLogoIcon: file(name: { eq: "bcl-logo-indie" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 42, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    profilePhoto: file(name: { eq: "bcl-logo-indie" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default Feedback;
